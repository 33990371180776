<template>
  <h1>Passwort ändern</h1>
  <div class="row justify-content-center gx-5 row-eq-height">
    <div class="col col-lg-5 col-md-6">
      <div class="card shadow">
        <form class="form" v-on:submit.prevent="submit">
          <div class="card-header" v-if="!isPwdChanged">
            <h3>Passwort ändern</h3>
          </div>
          <div class="card-header alert alert-success" v-else>
            <b-icon-check-circle-fill class="m-5" style="font-size: 5rem"/>
            <h3>E-Mail gesendet</h3>
          </div>
          <div class="card-body" v-if="isPwdChanged">
            <p>
              Es wurde eine E-Mail an die E-Mail-Adresse Deines Benutzerkontos gesendet.
              Bitte schaue in den Posteingang oder auch in den SPAM-Ordner Deines E-Mail-Kontos.
            </p>
            <p>Für die Änderung Deines Passwortes klicke bitte auf den Link in der E-Mail.</p>
            <p>Der Link ist nur für einen Tag gültig.</p>
          </div>
          <div class="card-body" v-else>
            <div class="input-group mb-3">
              <span class="input-group-text" id="password-icon"><b-icon-lock-fill /></span>
              <input type="password" class="form-control" id="pwd" autocomplete="new-password"
                     v-bind:class="`${pwdMeta.validated ? pwdMeta.valid ? 'is-valid' : 'is-invalid' : ''}`"
                     placeholder="Passwort" v-model="pwd" aria-label="password" aria-describedby="password-icon">
              <div class="invalid-feedback text-danger" style="text-align: left">
                {{ pwdError }}.
              </div>
            </div>
          </div>
          <div class="card-footer">
            <router-link class="btn btn-primary" :to="{ name: 'AuthLogin'}" v-if="isPwdChanged">zum Login</router-link>
            <button class="btn btn-primary" v-else :disabled="!meta.valid">Passwortänderung anfordern</button>
          </div>
        </form>
      </div>
    </div>
  </div>

</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import { useField, useForm } from 'vee-validate'
import '@/validations'
import '@/validations/auth'
import { changePwd } from '@/services/api/user/api'
import { useRoute } from 'vue-router'
import { Shop } from '@/store/modules/shop/interfaces'

export default defineComponent({
  name: 'AuthChangePwd',
  props: {
    shop: {
      type: Object as PropType<Shop>
    }
  },
  setup () {
    const route = useRoute()
    const isPwdChanged = ref(false)
    const isTokenError = ref(false)
    const token: string = route.params.token as string || ''
    const { meta } = useForm()
    const { value: pwd, errorMessage: pwdError, meta: pwdMeta } = useField(
      'pwd', { password: true, min: 5 }, { label: 'Passwort' })

    async function submit () {
      try {
        await changePwd(pwd.value as string, token)
      } catch (e) {
        isTokenError.value = true
      }
      isPwdChanged.value = true
    }

    return {
      isPwdChanged,
      isTokenError,
      token,
      meta,
      pwd,
      pwdError,
      pwdMeta,
      submit
    }
  }
})
</script>

<style scoped>

</style>
