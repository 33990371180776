import { defineRule } from 'vee-validate'
import { checkEmail, checkUsername } from '@/services/api/user/api'

defineRule('checkUser', async (value: string) => {
  if (value.length < 5) return true
  try {
    const check: boolean = await checkUsername(value)
    if (check) {
      return `${value} ist nicht verfügbar`
    }
  } catch {
    return 'Es ist ein Fehler aufgetreten. Bitte später noch einmal versuchen'
  }

  return true
})

defineRule('checkEmail', async (value: string) => {
  if (value.length < 6) return true
  try {
    const check: boolean = await checkEmail(value)
    if (check) {
      return `${value} ist nicht verfügbar`
    }
  } catch {
    return 'Es ist ein Fehler aufgetreten. Bitte später noch einmal versuchen'
  }

  return true
})

defineRule('password', (value: string) => {
  if (!value || !value.length) {
    return 'Passwort darf nicht leer sein'
  }

  // const moderate = /(?=.*[A-Z])(?=.*[a-z]).{5,}|(?=.*[\d])(?=.*[a-z]).{5,}|(?=.*[\d])(?=.*[A-Z])(?=.*[a-z]).{5,}/g
  const strong = /(?=.*[A-Z])(?=.*[a-z])(?=.*[\d]).{7,}|(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[A-Z])(?=.*[a-z]).{7,}/g
  // const extraStrong = /(?=.*[A-Z])(?=.*[a-z])(?=.*[\d])(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?]).{9,}/g

  if (!strong.test(value)) {
    return 'Passwort zu einfach - mind. 7-stellig mit Großbuchstaben, Kleinbuchstaben, Zahlen und / oder Sonderzeichen'
  }
  return true
})
