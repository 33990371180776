<template>
  <div class="container-lg">
    <h1>Willkommen zur Anmeldung</h1>
    <div class="row justify-content-center gx-5 row-eq-height">
      <div class="col-12 col-lg-5 col-sm-6">
        <div class="card shadow">
          <LoginForm/>
        </div>
      </div>
      <div class="col-12 col-lg-5 col-sm-6 text-center">
        <div class="card shadow h-100">
          <h3 class="card-header">
            Registrierung
          </h3>
          <div class="card-body">
            <h5>Du hast noch kein Benutzerkonto?</h5>
            <p>Klicke auf die Schaltfläche <span class="fw-bold">"zur Registrierung"</span> und erstelle Dir ein Benutzerkonto.</p>
          </div>
          <div class="card-footer">
            <button type="button" class="btn btn-primary" v-on:click="goToRegister">zur Registrierung</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import LoginForm from '@/components/auth/LoginForm.vue'
import { useRouter } from 'vue-router'
import { Shop } from '@/store/modules/shop/interfaces'

export default defineComponent({
  name: 'AuthLogin',
  components: {
    LoginForm
  },
  props: {
    shop: {
      type: Object as PropType<Shop>
    }
  },
  setup () {
    const router = useRouter()

    function goToRegister () {
      router.push({ name: 'AuthRegister' })
    }

    return {
      goToRegister
    }
  }
})
</script>

<style scoped>

</style>
