<template>
  <div class="container-lg">
    <h1>Passwort vergessen?</h1>
    <div class="row justify-content-center gx-5 row-eq-height">
      <div class="col col-lg-5 col-md-6">
        <div class="card shadow">
          <form class="form" v-on:submit.prevent="loginDispatch">
            <div class="card-header" v-if="!isEmailSend">
              <h3>Passwortänderung anfordern</h3>
            </div>
            <div class="card-header alert alert-success" v-else>
              <b-icon-check-circle-fill class="m-5" style="font-size: 5rem"/>
              <h3>E-Mail gesendet</h3>
            </div>
            <div class="card-body" v-if="isEmailSend">
              <p>
                Es wurde eine E-Mail an die E-Mail-Adresse Deines Benutzerkontos gesendet.
                Bitte schaue in den Posteingang oder auch in den SPAM-Ordner Deines E-Mail-Kontos.
              </p>
              <p>Für die Änderung Deines Passwortes klicke bitte auf den Link in der E-Mail.</p>
              <p>Der Link ist nur für einen Tag gültig.</p>
            </div>
            <div class="card-body" v-else>
              <div class="input-group mb-3">
                <span class="input-group-text" id="userident-icon"><b-icon-person-fill /></span>
                <input type="text" class="form-control"
                       v-bind:class="`${useridentMeta.validated ? useridentMeta.valid ? 'is-valid' : 'is-invalid' : ''}`"
                       id="userident" autocomplete="userident" aria-describedby="userident-icon"
                       placeholder="Benutzername oder E-Mail-Adresse" v-model="userident">
                <div class="invalid-feedback" style="text-align: left">
                  {{ useridentError }}.
                </div>
              </div>
              <p>
                Sobald Du die Passwortänderung für Deinen Benutzer anforderst, wird eine E-Mail mit einem
                Passwortänderungslink an die E-Mail-Adresse Deines Benutzerkontos gesendet.
              </p>
              <p>
                Der Passwortänderungslink ist maximal einen Tag gültig. Du kannst jederzeit einen neuen Link anfordern.
              </p>
              <p class="text-danger">
                ACHTUNG: Zur Verhinderung von Betrugsfällen wird nicht angezeigt, ob eine korrekte E-Mail oder
                ein korrekter Benutzername angegeben wurde.
              </p>
              <p class="text-danger">
                Falls Du keine E-Mail erhalten haben solltest, wiederhole bitte die Eingabe mit Deinem Benutzernamen oder
                Deiner E-Mail-Adresse.
              </p>
            </div>
            <div class="card-footer">
              <router-link class="btn btn-primary" :to="{ name: 'AuthLogin'}" v-if="isEmailSend">zum Login</router-link>
              <button class="btn btn-primary" v-else :disabled="!meta.valid">Passwortänderung anfordern</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import { useField, useForm } from 'vee-validate'
import '@/validations'
import { getNewPwdToken } from '@/services/api/user/api'
import { Shop } from '@/store/modules/shop/interfaces'
import { useStore } from '@/use/useStore'
import { AUTH_STORE } from '@/store/constants'
import { CurrentUser } from '@/store/modules/auth/interfaces'

export default defineComponent({
  name: 'AuthNewPwdToken',
  props: {
    shop: {
      type: Object as PropType<Shop>
    }
  },
  setup () {
    const store = useStore()
    const isEmailSend = ref(false)
    const { meta, setFieldValue } = useForm()
    const user = computed<CurrentUser | void>(() => store.getters[AUTH_STORE.GETTERS.GET_USER_DATA])
    const { value: userident, errorMessage: useridentError, meta: useridentMeta } = useField(
      'userident', { required: true, userident_or_email: true, min: 5 }, { label: 'Benutzername oder E-Mail' })

    if (user.value) {
      setFieldValue('userident', user.value.username)
    }
    async function loginDispatch () {
      await getNewPwdToken(userident.value as string)
      isEmailSend.value = true
    }

    return {
      isEmailSend,
      meta,
      userident,
      useridentError,
      useridentMeta,
      user,
      loginDispatch
    }
  }
})
</script>

<style scoped>

</style>
