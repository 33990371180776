<template>
  <div v-if="isLoggedIn">
    <div class="card-header alert alert-success">
      <b-icon-check-circle-fill class="m-5" style="font-size: 5rem"/>
      <h3>Anmeldung erfolgreich!</h3>
    </div>
    <div class="card-body">
      <h3>Hallo {{ currentUser.username }},</h3>
      <p>Du wurdest erfolgreich angemeldet.</p>
      <p>
        Du wirst nach 5 Sekunden automatisch weitergeleitet.
      </p>
    </div>
  </div>
  <div class="AuthLoginForm" v-if="!isLoggedIn">
    <h3 class="card-header">
      Anmelden
    </h3>
    <form class="form" v-on:submit.prevent="loginDispatch">
      <div class="card-body">
        <div class="alert alert-danger" role="alert" v-if="errorMsg !== ''">
          <span><b-icon-exclamation-triangle-fill/> {{ errorMsg }} <b-icon-exclamation-triangle-fill/></span>
        </div>
        <div class="alert alert-warning" v-show="errorMailNotVerified">
          <h4>Deine E-Mailadresse wurde noch nicht verifiziert.</h4>
          <p>Bitte schaue in Dein E-Mail-Postfach. Nach erfolgreicher Verifizierung kannst Du Dich anmelden.</p>
          <p>Keine E-Mail gefunden ?</p>
          <button type="button" class="btn btn-primary">Neue E-Mail anfordern</button>
        </div>
        <div class="input-group mb-3">
          <span class="input-group-text" id="user-icon"><b-icon-person-fill/></span>
          <input type="text" class="form-control" id="userident" autocomplete="username" aria-label="userident"
                 aria-describedby="user-icon"
                 placeholder="Benutzername oder E-Mail-Adresse" v-model="userident">
        </div>
        <div class="input-group mb-3">
          <span class="input-group-text" id="password-icon"><b-icon-lock-fill/></span>
          <input type="password" class="form-control" id="password" autocomplete="current-password"
                 placeholder="Passwort" v-model="pwd" aria-label="password" aria-describedby="password-icon">
        </div>
        <router-link :to="{ name: 'AuthNewPwdToken' }">Passwort vergessen ?</router-link>
      </div>
      <div class="card-footer">
        <button type="submit" class="btn btn-primary">Anmelden</button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useStore } from '@/use/useStore'
import { CurrentUser, Login } from '@/store/modules/auth/interfaces'
import { AUTH_STORE } from '@/store/constants'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'AuthLoginForm',
  setup () {
    const store = useStore()
    const router = useRouter()
    const userident = ref('')
    const pwd = ref('')
    const errorMsg = ref('')
    const errorMailNotVerified = ref(false)
    const isLoggedIn = computed(() => store.getters[AUTH_STORE.GETTERS.IS_LOGGED_IN])
    const currentUser = computed(() => store.getters[AUTH_STORE.GETTERS.GET_USER_DATA])

    async function loginDispatch () {
      const login: Login = { userident: userident.value, pwd: pwd.value }
      try {
        const response: CurrentUser = await store.dispatch(AUTH_STORE.ACTIONS.LOGIN, login)
        if (response) {
          setTimeout(() => {
            router.push({ name: 'UserProfile' })
          }, 5000)
        }
        errorMsg.value = ''
      } catch (e) {
        if (e && e.status && e.status === 403) {
          if (e.data && !e.data.success && e.data.err === 'email not verified') {
            errorMailNotVerified.value = true
          } else {
            errorMsg.value = 'Benutzername oder Passwort falsch!'
          }
        } else {
          errorMsg.value = 'Login fehlgeschlagen - Aktuell liegt ein technisches Problem vor. Bitte versuchen Sie es später noch einmal.'
        }
      }
    }

    return {
      isLoggedIn,
      currentUser,
      loginDispatch,
      userident,
      pwd,
      errorMsg,
      errorMailNotVerified
    }
  }
})
</script>

<style scoped>

</style>
