<template>
  <div class="container-lg row justify-content-center gx-lg-5 gx-md-3">
    <div class="col-lg-6 col-md-6">
      <div class="card shadow">
        <auth-register-form />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AuthRegisterForm from '@/components/auth/RegisterForm.vue'

export default defineComponent({
  name: 'AuthRegister',
  components: {
    AuthRegisterForm
  }
})
</script>

<style scoped>

</style>
