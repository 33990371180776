<template>
  <form class="AuthRegisterForm" v-on:submit.prevent="signUp" v-if="!signedUp">
    <div class="card-header"><h3>Registrierung als neuer Kunde</h3></div>
    <div class="card-body">
      <div class="alert alert-danger" v-if="isError">Bitte überprüfe Deine Angaben.</div>
      <div class="input-group mb-3">
        <span class="input-group-text" id="user-icon"><b-icon-person-fill /></span>
        <input type="text" class="form-control"
               v-bind:class="`${usernameMeta.validated ? usernameMeta.valid ? 'is-valid' : 'is-invalid' : ''}`"
               id="username" autocomplete="username" aria-describedby="user-icon"
               placeholder="Mein Benutzername / Spitzname" v-model="username">
        <div class="invalid-feedback" style="text-align: left">
          {{ usernameError }}.
        </div>
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text" id="mail-icon"><b-icon-envelope-fill /></span>
        <input type="email" class="form-control" id="email" autocomplete="username"
               v-bind:class="`${emailMeta.validated ? emailMeta.valid ? 'is-valid' : 'is-invalid' : ''}`"
               placeholder="name@email.de" v-model="email" aria-label="email" aria-describedby="mail-icon">
        <div class="invalid-feedback text-danger" style="text-align: left">
          {{ emailError }}.
        </div>
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text" id="password-icon"><b-icon-lock-fill /></span>
        <input type="password" class="form-control" id="pwd" autocomplete="new-password"
               v-bind:class="`${pwdMeta.validated ? pwdMeta.valid ? 'is-valid' : 'is-invalid' : ''}`"
               placeholder="Passwort" v-model="pwd" aria-label="password" aria-describedby="password-icon">
        <div class="invalid-feedback text-danger" style="text-align: left">
          {{ pwdError }}.
        </div>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="dsgvo" v-model="dsgvoAccepted">
        <label class="form-check-label" style="text-align: left" for="dsgvo">
          <span v-html="dsgvoTxt"></span>
        </label>
      </div>
    </div>
    <div class="card-footer">
      <button type="submit" class="btn btn-primary" :disabled="!meta.valid">Registrieren & Anmelden</button>
    </div>
  </form>
  <div v-if="signedUp">
    <div class="card-header">
      <h3>Herzlich Willkommen {{ currentUser.username }} !</h3>
    </div>
    <div class="card-body">
      <p>
        Du wurdest erfolgreich registriert und es wurde eine Verifizierungsmail an Deine E-Mail-Adresse: {{ currentUser.email }} gesendet.
      </p>
      <p>
        Bitte öffne Dein E-Mail-Postfach und klicke auf den Verifizierungslink in der E-Mail.<br>
        Nach erfolgreicher E-Mail-Verifizierung kannst Du Dich mit Deinen Registrierungsdaten anmelden.
      </p>
    </div>
    <div class="card-footer">
      <p>Du hast keine E-Mail erhalten ?</p>
      <button type="button" class="btn btn-primary">Neue E-Mail anfordern</button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useStore } from '@/use/useStore'
import { CurrentUser, SignUp } from '@/store/modules/auth/interfaces'
import { AUTH_STORE } from '@/store/constants'
import { useField, useForm } from 'vee-validate'
import '@/validations'
import '@/validations/auth'

export default defineComponent({
  name: 'AuthRegisterForm',
  setup () {
    const { meta } = useForm()
    const { value: username, errorMessage: usernameError, meta: usernameMeta } = useField(
      'username', { required: true, alpha_dash: true, min: 5, checkUser: true }, { label: 'Benutzername' })
    const { value: email, errorMessage: emailError, meta: emailMeta } = useField(
      'email', { required: true, email: true, min: 5, checkEmail: true }, { label: 'E-Mail' })
    const { value: pwd, errorMessage: pwdError, meta: pwdMeta } = useField(
      'pwd', { password: true, min: 5 }, { label: 'Passwort' })
    const { value: dsgvoAccepted, errorMessage: dsgvoAcceptedError, meta: dsgvoAcceptedMeta } = useField(
      'dsgvoAccepted', { required: true }, { label: 'Datenschutz' })

    const store = useStore()
    const currentUser = computed(() => store.getters[AUTH_STORE.GETTERS.GET_USER_DATA])

    const isError = ref(false)
    const errors = ref([])
    const dsgvoTxt = 'Ich habe die <a class="link-primary" href="/Datenschutz" target="_blank">Datenschutzerklärung</a> ' +
      'zur Kenntnis genommen.\n'
    const signedUp = ref(false)

    async function signUp () {
      isError.value = false
      const signUp: SignUp = {
        username: username.value as string,
        email: email.value as string,
        pwd: pwd.value as string,
        dsgvoAccepted: dsgvoAccepted.value as boolean,
        dsgvoTxt: dsgvoTxt
      }
      try {
        const response = await store.dispatch(AUTH_STORE.ACTIONS.SIGN_UP, signUp)
        if (response) signedUp.value = true
        isError.value = true
      } catch (e) {
        isError.value = true
        if (e.data && e.data.errors) {
          errors.value = e.data.errors
        }
      }
    }
    return {
      meta,
      isError,
      errors,
      username,
      usernameError,
      usernameMeta,
      email,
      emailError,
      emailMeta,
      pwd,
      pwdError,
      pwdMeta,
      dsgvoAccepted,
      dsgvoAcceptedError,
      dsgvoAcceptedMeta,
      dsgvoTxt,
      signedUp,
      signUp,
      currentUser
    }
  }
})
</script>

<style scoped>

</style>
