
import { defineComponent, PropType, ref } from 'vue'
import { useField, useForm } from 'vee-validate'
import '@/validations'
import '@/validations/auth'
import { changePwd } from '@/services/api/user/api'
import { useRoute } from 'vue-router'
import { Shop } from '@/store/modules/shop/interfaces'

export default defineComponent({
  name: 'AuthChangePwd',
  props: {
    shop: {
      type: Object as PropType<Shop>
    }
  },
  setup () {
    const route = useRoute()
    const isPwdChanged = ref(false)
    const isTokenError = ref(false)
    const token: string = route.params.token as string || ''
    const { meta } = useForm()
    const { value: pwd, errorMessage: pwdError, meta: pwdMeta } = useField(
      'pwd', { password: true, min: 5 }, { label: 'Passwort' })

    async function submit () {
      try {
        await changePwd(pwd.value as string, token)
      } catch (e) {
        isTokenError.value = true
      }
      isPwdChanged.value = true
    }

    return {
      isPwdChanged,
      isTokenError,
      token,
      meta,
      pwd,
      pwdError,
      pwdMeta,
      submit
    }
  }
})
