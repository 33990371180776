<template>
  <div class="container-lg">
    <h1>Anforderung einer neuen Verifizierungs-E-Mail</h1>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { verifyEmail } from '@/services/api/user/api'

export default defineComponent({
  name: 'AuthNewVerify',
  setup () {
    const router = useRouter()
    const route = useRoute()
    const token: string = route.params.token as string || ''
    const isLoading = ref(true)
    const isVerified = ref(false)
    const isError = ref(false)
    const error = ref('')
    const verify = async () => {
      if (token === '') {
        isLoading.value = false
        isError.value = true
        error.value = 'token invalid'
        return
      }
      try {
        const response = await verifyEmail(token)
        isLoading.value = false
        if (response) isVerified.value = true
      } catch (e) {
        isLoading.value = false
        isVerified.value = false
        isError.value = true
        error.value = ''
        if (e.status && e.status === 401) {
          if (e.data && e.data.errors && e.data.errors.length > 0) {
            // check for token error
            if (e.data.errors[0].param === 'token') {
              error.value = 'token invalid'
            }
          }
        }
      }
    }

    function goToLogin () {
      router.push({ name: 'AuthLogin' })
    }

    onMounted(() => verify())

    return {
      isLoading,
      isVerified,
      goToLogin,
      isError,
      error,
      verify
    }
  }
})
</script>

<style scoped>

</style>
