
import { defineComponent } from 'vue'
import AuthRegisterForm from '@/components/auth/RegisterForm.vue'

export default defineComponent({
  name: 'AuthRegister',
  components: {
    AuthRegisterForm
  }
})
