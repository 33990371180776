
import { defineComponent, PropType } from 'vue'
import LoginForm from '@/components/auth/LoginForm.vue'
import { useRouter } from 'vue-router'
import { Shop } from '@/store/modules/shop/interfaces'

export default defineComponent({
  name: 'AuthLogin',
  components: {
    LoginForm
  },
  props: {
    shop: {
      type: Object as PropType<Shop>
    }
  },
  setup () {
    const router = useRouter()

    function goToRegister () {
      router.push({ name: 'AuthRegister' })
    }

    return {
      goToRegister
    }
  }
})
