
import { computed, defineComponent, PropType, ref } from 'vue'
import { useField, useForm } from 'vee-validate'
import '@/validations'
import { getNewPwdToken } from '@/services/api/user/api'
import { Shop } from '@/store/modules/shop/interfaces'
import { useStore } from '@/use/useStore'
import { AUTH_STORE } from '@/store/constants'
import { CurrentUser } from '@/store/modules/auth/interfaces'

export default defineComponent({
  name: 'AuthNewPwdToken',
  props: {
    shop: {
      type: Object as PropType<Shop>
    }
  },
  setup () {
    const store = useStore()
    const isEmailSend = ref(false)
    const { meta, setFieldValue } = useForm()
    const user = computed<CurrentUser | void>(() => store.getters[AUTH_STORE.GETTERS.GET_USER_DATA])
    const { value: userident, errorMessage: useridentError, meta: useridentMeta } = useField(
      'userident', { required: true, userident_or_email: true, min: 5 }, { label: 'Benutzername oder E-Mail' })

    if (user.value) {
      setFieldValue('userident', user.value.username)
    }
    async function loginDispatch () {
      await getNewPwdToken(userident.value as string)
      isEmailSend.value = true
    }

    return {
      isEmailSend,
      meta,
      userident,
      useridentError,
      useridentMeta,
      user,
      loginDispatch
    }
  }
})
