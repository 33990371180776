<template>
  <div class="container-lg">
    <h1>Verifizierung Deiner E-Mail-Adresse</h1>
    <div class="alert alert-info" v-show="isLoading">
      Die Verifizierung Deiner E-Mail-Adresse wird durchgeführt. Bitte warten ...
    </div>
    <div class="alert alert-success" v-show="isVerified">
      <h3>Die Verifizierung Deiner E-Mail-Adresse war erfolgreich.</h3>
      <p>Du kannst Dich nun auf unserer Seite anmelden.</p>
      <router-link class="btn btn-primary" :to="{ name: 'AuthLogin' }">zum Login</router-link>
    </div>
    <div  class="alert alert-danger" v-show="isError">
      <h3>Die E-Mail-Verifizierung ist fehlgeschlagen!!!</h3>
      <div v-if="`${ (error === 'token invalid') }`">
        <p class="fw-bold">Der übermittelte Verifizierungstoken ist ungültig.</p>
        <p>
          Du kanst gern eine neue Verifizierungs-Email anfordern.
          Bitte beachte, dass Du innerhalb eines Tages die Verifizierung Deiner E-Mail durchführst.
        </p>
      </div>
      <div v-else>
        <p class="fw-bold">Bei der Verifizierung Deiner E-Mail-Adresse ist ein Fehler aufgetreten.</p>
        <p>Sollte das Prolem weiterhin bestehen, kannst Du gern eine E-Mail an:<br><br>
          kontakt@shop-vor-ort.de<br><br>schreiben.</p>
        <p>Wir werden uns dann umgehend bei Dir melden.</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { verifyEmail } from '@/services/api/user/api'

export default defineComponent({
  name: 'AuthVerify',
  setup () {
    const router = useRouter()
    const route = useRoute()
    const token: string = route.params.token as string || ''
    const isLoading = ref(true)
    const isVerified = ref(false)
    const isError = ref(false)
    const error = ref('')
    const verify = async () => {
      if (token === '') {
        isLoading.value = false
        isError.value = true
        error.value = 'token invalid'
        return
      }
      try {
        const response = await verifyEmail(token)
        isLoading.value = false
        if (response) {
          isVerified.value = true
          setTimeout(() => {
            router.push({ name: 'AuthLogin' })
          }, 5000)
        }
      } catch (e) {
        isLoading.value = false
        isVerified.value = false
        isError.value = true
        error.value = ''
        if (e.status && e.status === 401) {
          if (e.data && e.data.errors && e.data.errors.length > 0) {
            // check for token error
            if (e.data.errors[0].param === 'token') {
              error.value = 'token invalid'
            }
          }
        }
      }
    }

    function goToLogin () {
      router.push({ name: 'AuthLogin' })
    }

    onMounted(() => verify())

    return {
      isLoading,
      isVerified,
      goToLogin,
      isError,
      error,
      verify
    }
  }
})
</script>

<style scoped>

</style>
