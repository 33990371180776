
import { computed, defineComponent, ref } from 'vue'
import { useStore } from '@/use/useStore'
import { CurrentUser, Login } from '@/store/modules/auth/interfaces'
import { AUTH_STORE } from '@/store/constants'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'AuthLoginForm',
  setup () {
    const store = useStore()
    const router = useRouter()
    const userident = ref('')
    const pwd = ref('')
    const errorMsg = ref('')
    const errorMailNotVerified = ref(false)
    const isLoggedIn = computed(() => store.getters[AUTH_STORE.GETTERS.IS_LOGGED_IN])
    const currentUser = computed(() => store.getters[AUTH_STORE.GETTERS.GET_USER_DATA])

    async function loginDispatch () {
      const login: Login = { userident: userident.value, pwd: pwd.value }
      try {
        const response: CurrentUser = await store.dispatch(AUTH_STORE.ACTIONS.LOGIN, login)
        if (response) {
          setTimeout(() => {
            router.push({ name: 'UserProfile' })
          }, 5000)
        }
        errorMsg.value = ''
      } catch (e) {
        if (e && e.status && e.status === 403) {
          if (e.data && !e.data.success && e.data.err === 'email not verified') {
            errorMailNotVerified.value = true
          } else {
            errorMsg.value = 'Benutzername oder Passwort falsch!'
          }
        } else {
          errorMsg.value = 'Login fehlgeschlagen - Aktuell liegt ein technisches Problem vor. Bitte versuchen Sie es später noch einmal.'
        }
      }
    }

    return {
      isLoggedIn,
      currentUser,
      loginDispatch,
      userident,
      pwd,
      errorMsg,
      errorMailNotVerified
    }
  }
})
