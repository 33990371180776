
import { computed, defineComponent, ref } from 'vue'
import { useStore } from '@/use/useStore'
import { CurrentUser, SignUp } from '@/store/modules/auth/interfaces'
import { AUTH_STORE } from '@/store/constants'
import { useField, useForm } from 'vee-validate'
import '@/validations'
import '@/validations/auth'

export default defineComponent({
  name: 'AuthRegisterForm',
  setup () {
    const { meta } = useForm()
    const { value: username, errorMessage: usernameError, meta: usernameMeta } = useField(
      'username', { required: true, alpha_dash: true, min: 5, checkUser: true }, { label: 'Benutzername' })
    const { value: email, errorMessage: emailError, meta: emailMeta } = useField(
      'email', { required: true, email: true, min: 5, checkEmail: true }, { label: 'E-Mail' })
    const { value: pwd, errorMessage: pwdError, meta: pwdMeta } = useField(
      'pwd', { password: true, min: 5 }, { label: 'Passwort' })
    const { value: dsgvoAccepted, errorMessage: dsgvoAcceptedError, meta: dsgvoAcceptedMeta } = useField(
      'dsgvoAccepted', { required: true }, { label: 'Datenschutz' })

    const store = useStore()
    const currentUser = computed(() => store.getters[AUTH_STORE.GETTERS.GET_USER_DATA])

    const isError = ref(false)
    const errors = ref([])
    const dsgvoTxt = 'Ich habe die <a class="link-primary" href="/Datenschutz" target="_blank">Datenschutzerklärung</a> ' +
      'zur Kenntnis genommen.\n'
    const signedUp = ref(false)

    async function signUp () {
      isError.value = false
      const signUp: SignUp = {
        username: username.value as string,
        email: email.value as string,
        pwd: pwd.value as string,
        dsgvoAccepted: dsgvoAccepted.value as boolean,
        dsgvoTxt: dsgvoTxt
      }
      try {
        const response = await store.dispatch(AUTH_STORE.ACTIONS.SIGN_UP, signUp)
        if (response) signedUp.value = true
        isError.value = true
      } catch (e) {
        isError.value = true
        if (e.data && e.data.errors) {
          errors.value = e.data.errors
        }
      }
    }
    return {
      meta,
      isError,
      errors,
      username,
      usernameError,
      usernameMeta,
      email,
      emailError,
      emailMeta,
      pwd,
      pwdError,
      pwdMeta,
      dsgvoAccepted,
      dsgvoAcceptedError,
      dsgvoAcceptedMeta,
      dsgvoTxt,
      signedUp,
      signUp,
      currentUser
    }
  }
})
